Q<template>
  <div data-cy="queue-slot-with-insert">
    <!-- Scheduling between slots -->
    <div class="relative">
      <div
        data-cy="queue-insert-row"
        v-if="index !== 0 && showAddTweetBetweenSlot"
        class="scheduling-slot-insert-row"
        :class="tweetOptions && 'active'"
        v-click-outside="hideTweetOptions"
        @mousemove="getPosition"
        @click="showTweetOptions"
        v-touch:start="(showAddTweetBetweenSlot = true)"
      >
        <div class="container" :class="{ mobile: isMobile }">
          <div class="trigger" :style="{ left: position + 'px' }"></div>
          <button v-if="isMobile" class="schedule-icon">
            <inline-svg src="/img/icons/add.svg" />
          </button>
        </div>

        <div
          class="absolute -ml-10 -mt-12 flex transform items-center gap-x-2"
          v-if="tweetOptions"
          :style="styleForTweetBetweenSlots"
        >
          <new-button
            v-for="(action, index) in betweenSlotsAction"
            :key="`between-slots-action-${index}`"
            :data-cy="action.dataCy"
            icon
            size="lg"
            @click.stop="action.onClick"
          >
            <inline-svg :src="action.icon" class="h-5 w-5 stroke-current" />
          </new-button>
        </div>
      </div>
    </div>
    <queue-tweet-card
      @delete-thread="onDeleteThread(post)"
      @edit-post="newEditPostAction"
      @post-now="postNow(post)"
      v-if="post && (post.tweets || post.thread)"
      :post="post"
      :userTimezone="userTimezone"
      :draggedPost="draggedPost"
      :postTime="postTime"
    />

    <queue-retweet-card
      v-else-if="post && post.type === 'retweet'"
      @delete-thread="onDeleteThread(post)"
      @edit-post="newEditPostAction"
      @post-now="postNow(post)"
      :post="post"
      :userTimezone="userTimezone"
      :draggedPost="draggedPost"
      :postTime="postTime"
    />

    <queue-recurrent-card
      v-else-if="post && ['recurrent', 'evergreen'].includes(post.slotType)"
      :post="post"
      :userTimezone="userTimezone"
      :postTime="postTime"
    />

    <!-- Empty slot -->
    <div
      v-else-if="!post || ['facebook', 'instagram', 'linkedin', 'threads'].includes(post.slotType)"
      :data-cy="
        !post
          ? 'queue-card-empty'
          : post.slotType === 'facebook'
          ? 'queue-card-facebook'
          : post.slotType === 'instagram'
          ? 'queue-card-instagram'
          : post.slotType === 'linkedin'
          ? 'queue-card-linkedin'
          : ''
      "
      @dragleave="dragLeaveEmptySlot(post, $event)"
      @dragover="
        arePostAndSlotOfTheSameType(draggedPost, post) ? dragEnterEmptySlot(post, $event) : null
      "
      @drop="
        arePostAndSlotOfTheSameType(draggedPost, post)
          ? dropEmptySlot(postTimeMoment, $event)
          : null
      "
      :class="{ 'drag-hovered-slot border-2 border-blue-20': isDragEntered }"
    >
      <queue-card-container
        @click.native="sendToNewComposer()"
        class="cursor-pointer"
        :hasBody="false"
      >
        <template #header>
          <div data-cy="queue-card-time" class="card-header flex items-center text-base leading-6">
            <p class="">{{ formatTimeWithUserLocale(postTimeMoment) }}</p>
            <template v-if="post && post.slotType === 'instagram'">
              <inline-svg
                src="/img/icons/new/socials/instagram.svg"
                class="mx-2 inline h-5 w-5 fill-ds-button-secondary-label"
              />
              <p>Instagram Slot</p>
            </template>

            <template v-if="post && post.slotType === 'facebook'">
              <inline-svg
                src="/img/icons/new/socials/facebook.svg"
                class="mx-2 inline h-5 w-5 fill-ds-button-secondary-label"
              />
              <p>Facebook Slot</p>
            </template>

            <template v-if="post && post.slotType === 'linkedin'">
              <inline-svg
                src="/img/icons/new/socials/linkedin.svg"
                class="mx-2 inline h-5 w-5 fill-ds-button-secondary-label"
              />
              <p>LinkedIn Slot</p>
            </template>
            <template v-if="post && post.slotType === 'threads'">
              <inline-svg
                src="/img/icons/new/socials/threads.svg"
                class="mx-2 inline h-5 w-5 text-ds-button-secondary-label"
              />
              <p class="text-lg">Threads Slot</p>
            </template>
          </div>

          <div
            v-if="!post || !['facebook', 'instagram', 'linkedin'].includes(post.slotType)"
            class="flex items-center opacity-100 group-hover:visible group-hover:opacity-100 md:invisible md:opacity-0"
          >
            <tooltip content="Reply">
              <new-button
                @click.prevent.stop="openNewReplyComposer()"
                type="transparent"
                size="sm"
                data-cy="queue-card-reply-button"
                class="space-x-2 p-0"
              >
                <inline-svg
                  src="/img/icons/new/new-reply.svg"
                  class="h-5 w-5 stroke-main-color-100"
                />
                <p class="text-ds-button-primary-background">Reply</p>
              </new-button>
            </tooltip>
            <tooltip content="Retweet">
              <new-button
                @click.prevent.stop="openNewRetweetModal()"
                type="transparent"
                size="sm"
                data-cy="queue-card-retweet-button"
                class="space-x-2"
              >
                <inline-svg
                  src="/img/icons/new-retweet-icon.svg"
                  class="h-5 w-5 stroke-main-color-100"
                />
                <p class="text-ds-button-primary-background">Retweet</p>
              </new-button>
            </tooltip>
            <tooltip content="Write">
              <new-button type="primary" size="sm" data-cy="queue-card-write-button">
                <p>Write</p>
              </new-button>
            </tooltip>
          </div>
        </template>
      </queue-card-container>
    </div>
  </div>
</template>

<script>
  import TimeFormatterMixin from '@/views/Mixins/TimeFormatterMixin';
  import DeletePostMixin from '@/views/Mixins/DeletePostMixin';
  import DragAndDropMixin from '@/views/Mixins/DragAndDropMixin';
  import RetweetMixin from '@/views/Mixins/RetweetMixin';
  import { formatTweet } from '@/util/formatTweet';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import lodash from 'lodash';
  import MobileMixin from '../views/Mixins/MobileMixin.vue';
  import ThreadMixinVue from '../views/Mixins/ThreadMixin.vue';
  import GetHypefurySubscriptionMixin from '@/views/Mixins/GetHypefurySubscriptionMixin';
  import GlobalSettingsMixin from '../views/Mixins/GlobalSettingsMixin.vue';
  import PostStatusMixin from '../views/Mixins/PostStatusMixin.vue';
  import { EventBus } from '@/event-bus';
  import QueueCardContainer from '@/components/Queue/QueueCardContainer.vue';
  import QueueTweetCard from '@/components/Queue/QueueTweetCard.vue';
  import QueueRetweetCard from '@/components/Queue/QueueRetweetCard.vue';
  import QueueRecurrentCard from '@/components/Queue/QueueRecurrentCard.vue';
  import CustomerStatusMixin from '../views/Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'queue-slot',
    data() {
      return {
        hoveredRetweetSlot: null,
        showAddTweetBetweenSlot: !this.isMobile,
        position: 0,
        tweetOptions: false,
        threadToEdit: null,
        showExpandedThread: false,
      };
    },
    props: {
      index: {
        type: Number,
      },
      indicesOfEmptySlots: {
        type: Array,
      },
      post: {
        type: Object,
      },
      draggedPost: {
        type: Object,
      },
      customerStatus: {
        type: String,
      },
      postTime: {
        type: String,
      },
      userTimezone: {
        type: String,
      },
      previousThread: {
        type: Object,
      },
      handleDeleteThread: {
        type: Function,
      }
    },
    components: {
      QueueCardContainer,
      QueueTweetCard,
      QueueRetweetCard,
      QueueRecurrentCard,
    },
    computed: {
      styleForTweetBetweenSlots() {
        return {
          left: `${this.isMobile ? (this.windowInnerWidth / 2) - 50 : this.position }px`
        }
      },
      windowInnerWidth(){
        return window.innerWidth;
      },
      postTimeMoment() {
        return moment(this.postTime).tz(this.userTimezone);
      },
      middlePostTimeMoment() {
        return moment(this.previousThread).tz(this.userTimezone);
      },
      betweenSlotsAction() {
        return [
          {
            dataCy: 'between-slots-schedule-thread',
            icon: '/img/icons/edit.svg',
            onClick: () => this.sendToNewComposer(this.previousThread.time),
          },
          {
            dataCy: 'between-slots-schedule-retweet',
            icon: '/img/icons/new-retweet-icon.svg',
            onClick: () => this.openNewRetweetModal(this.previousThread.time),
          },
          {
            dataCy: 'between-slots-schedule-reply',
            icon: '/img/icons/new/new-reply.svg',
            onClick: () => this.openNewReplyComposer(this.previousThread),
          },
          {
            dataCy: 'between-slots-cancel',
            icon: '/img/icons/close.svg',
            onClick: () => this.hideTweetOptions(),
          },
        ];
      },
      ...mapGetters({ userProfile: 'getUserProfile' }),
    },
    methods: {
      getMedia(source, index) {
        if (source.tweets) {
          if (source.tweetReel && source.tweetReel.outputFileUrl) {
            return [{ url: source.tweetReel.outputFileUrl, type: 'reel' }];
          } else if (source.tweets[index].media) {
            return source.tweets[index].media;
          }
          return [];
        }
        return [];
      },
      newEditPostAction() {
        if (this.post.replyToTweetId) {
          this.openNewReplyComposer();
        } else {
          this.sendToNewComposer();
        }
      },
      sendToNewComposer(previousTime) {
        let time = this.postTimeMoment;
        if (previousTime) {
          time = this.calculateTimeBetweenSlots(previousTime, this.postTimeMoment);
        }

        const getPostType = () => {
          if (this.post.source === 'instagram-post') {
            return 'instagram';
          }

          if (this.post.source === 'facebook-post') {
            return 'facebook';
          }

          if (this.post.source === 'linkedin-post') {
            return 'linkedin';
          }

          if (this.post.source === 'threads-post') {
            return 'threads';
          }

          if (this.post.slotType !== 'post') {
            return this.post.slotType;
          }

          return 'twitter';
        };

        const post =
          lodash.get(this.post, 'slotType') === 'post' && !previousTime ? this.post : null;

        EventBus.$emit('open-new-composer', {
          time,
          post,
          postType: lodash.get(this.post, 'slotType') && !previousTime ? getPostType() : 'twitter',
          ...(lodash.get(this.post, 'slotType', null) !== null && !previousTime
            ? {
                source: {
                  name: 'Queue',
                },
              }
            : {}),
          ...(lodash.get(this.post, 'tweetReel', null) !== null
            ? {
                selectedTab: 'reel-editor',
                reelOptions: {
                  options: this.post.tweetReel.options,
                  tweet: this.post.tweetReel.tweet,
                },
              }
            : {}),
          hasStartedEditing: post === null ? false : true,
        });
      },
      formatTweet(tweet) {
        return formatTweet(tweet);
      },
      openNewReplyComposer(previousTime) {
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp('Schedule a reply', 'schedule a reply');
          return;
        }

        let time = this.postTimeMoment;
        if (previousTime) {
          time = this.calculateTimeBetweenSlots(previousTime, this.postTimeMoment);
        }

        EventBus.$emit('open-new-composer', {
          time,
          post: lodash.get(this.post, 'slotType') === 'post' && !previousTime ? this.post : null,
          ...(lodash.get(this.post, 'slotType', null) !== null && !previousTime
            ? {
                source: {
                  name: 'Queue',
                },
              }
            : {}),
          isAReply: true,
          hasStartedEditing: true,
        });
      },
      openNewRetweetModal(previousTime) {
        if (this.userProfile.customerStatus === 'none') {
          this.displayGetHypefurySubscriptionPopUp(
            'Schedule a retweet',
            'schedule a retweet'
          );
          return;
        }

        let time = this.postTimeMoment;
        if (previousTime) {
          time = this.calculateTimeBetweenSlots(previousTime, this.postTimeMoment);
        }

        this.$emit('open-new-retweet-modal', time);
      },
      postNow(post) {
        this.$emit('post-now', post);
      },
      calculateTimeBetweenSlots(previousTime, currentTime) {
        const calculatedTime = moment(previousTime).add(
          moment.duration(currentTime.diff(previousTime)) / 2
        );
        this.currentSlotLength(calculatedTime);
        if (this.slotLength === 2 || currentTime.diff(previousTime, 'minutes') === 2) {
          this.$notify({ type: 'warning', message: 'You cannot tweet on this time slot' });
        } else {
          return calculatedTime;
        }
      },
      getPosition(event) {
        if (!this.tweetOptions) {
          var bounds = event.target.getBoundingClientRect();
          var x = event.clientX - bounds.left;
          this.position = x;
        }
      },
      onDeleteThread(post) {
        if (this.handleDeleteThread) {
          this.handleDeleteThread(post);
        } else {
          this.deleteThread(post);
        }
      },
      showTweetOptions() {
        this.tweetOptions = true;
      },
      hideTweetOptions() {
        this.tweetOptions = false;
      },
      arePostAndSlotOfTheSameType(draggedPost, slot) {
        const getPostSlotType = (post) => {
          if (!post) {
            return null;
          }

          if (post.source === 'instagram-post') {
            return 'instagram';
          } else if (post.source === 'linkedin-post') {
            return 'linkedin';
          } else if (post.source === 'facebook-post') {
            return 'facebook';
          } else if (post.source === 'threads-post') {
            return 'threads';
          } else {
            return null;
          }
        };

        const postSlotType = getPostSlotType(draggedPost);
        const slotType = lodash.get(slot, 'id') ? getPostSlotType(slot) : lodash.get(slot, 'slotType', null);

        return postSlotType === slotType;
      },
    },
    mounted() {
      const tweetDisplay = this.$refs.tweetDisplay;
      this.$nextTick(() => {
        if (tweetDisplay) {
          if (tweetDisplay.clientHeight < 350) {
            this.showExpandedThread = true;
          }
        }
      });
    },
    mixins: [
      GetHypefurySubscriptionMixin,
      TimeFormatterMixin,
      DeletePostMixin,
      DragAndDropMixin,
      RetweetMixin,
      MobileMixin,
      ThreadMixinVue,
      GlobalSettingsMixin,
      PostStatusMixin,
      CustomerStatusMixin,
    ],
  };
</script>
<style scoped lang="scss">
  .tweetshot {
    position: relative;
    background: var(--tweetshot);

    ::v-deep a {
      color: inherit;
    }

    .arrow {
      width: 0;
      height: 0;
      border-top: 16px solid var(--tweetshot);
      border-bottom: 16px solid transparent;
      border-left: 16px solid var(--tweetshot);
      position: absolute;
      bottom: -16px;
      clip-path: polygon(100% 50%, 0 50%, 0 100%);
    }
  }

  .card-header {
    font-weight: 450;
    svg {
      color: var(--button-secondary-label) !important;
    }
  }
</style>
