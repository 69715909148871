<template>
  <div
    class="queue-day-header dark-mode-bg-80 z-0 mb-4 flex items-baseline justify-between"
    :class="{ 'bg-white': stickyEnabled }"
    v-sticky
    on-stick="onStick"
  >
    <div class="text-base font-medium lg:text-lg">
      <span>{{ midnight | formatNameOfDay(userTimezone) }}</span>
      <span v-if="!isFirst">
        {{ midnight | formatDateOfDay(userTimezone) }}
      </span>
    </div>
    <fade-transition>
      <div class="text-dark-mode-15" v-if="isFirst && currentTime">
        Time zone: {{ getShortTimezone().replace(/_/, ' ') }} ({{ currentTime }})
      </div>
    </fade-transition>
  </div>
</template>

<script>
  import FadeTransition from 'vue2-transitions/src/Fade/FadeTransition';
  import moment from 'moment';
  import Sticky from 'vue-sticky-directive';
  import TimeFormatterMixin from '@/views/Mixins/TimeFormatterMixin.vue';

  export default {
    components: {
      FadeTransition,
    },
    directives: { Sticky },
    props: {
      userTimezone: {
        type: String,
      },
      midnight: {
        type: String,
      },
      isFirst: {
        type: Boolean,
      },
    },
    data() {
      return {
        currentTime: null,
        stickyEnabled: false,
      };
    },
    filters: {
      formatNameOfDay: function (time, timezone) {
        const midnightOfToday = moment.tz(timezone).startOf('day');
        const midnightOfTime = moment.tz(time, timezone).startOf('day');

        if (midnightOfToday.diff(midnightOfTime, 'day') === 0) return 'Today';
        if (midnightOfToday.diff(midnightOfTime, 'day') === -1) return 'Tomorrow';
        if (midnightOfToday.diff(midnightOfTime, 'day') === 1) return 'Yesterday';
        return midnightOfTime.format('dddd');
      },
      formatDateOfDay: function (time, timezone) {
        const midnightOfToday = moment.tz(timezone).startOf('day');
        const midnightOfTime = moment.tz(time, timezone).startOf('day');

        if (midnightOfToday.diff(midnightOfTime, 'day') === 0) return '';
        return moment.tz(time, timezone).format('MMMM DD');
      },
    },
    created() {
      if (this.isFirst) {
        this.updateCurrentTime();
        window.setInterval(() => this.updateCurrentTime(), 1000);
      }
    },
    methods: {
      updateCurrentTime() {
        if (this.userTimezone) {
          this.currentTime = this.formatTimeWithUserLocale(moment().tz(this.userTimezone));
        }
      },
      getShortTimezone() {
        const split = this.userTimezone.split('/');
        return split[split.length - 1];
      },
      onStick(data) {
        this.stickyEnabled = data.sticked;
      },
    },
    mixins: [TimeFormatterMixin],
  };
</script>
<style scoped>
  .queue-day-header {
    pointer-events: none;
    z-index: 1 !important;
  }
</style>
